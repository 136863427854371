














































































































































import { InviteLink } from '@/includes/types/InviteLink.types'
import { successNotification } from '@/includes/services/NotificationService'

import { copyTokenToClipboard } from 'piramis-base-components/src/logic/helpers/copy-to-clipboard'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'

import Component from 'vue-class-component'
import { Emit, Mixins, Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'

@Component({
  'components': {
    VueApexCharts,
    Icon,
  }
})
export default class LinkCard extends Mixins<ClipboardCopyMixin>(ClipboardCopyMixin) {
  @Prop() link!: InviteLink

  @Prop() stat!: any

  @Prop() chartType !: string

  @Emit('editLinkClick')
  editLinkClick(): InviteLink {
    return this.link
  }

  @Emit('deleteLinkClick')
  deleteLinkClick(): any {
    return this.link.code
  }

  get getNeededMetrics() {
    if (this.chartType === 'line') {
      return this.stat.series.filter((item: any) => item.name === this.$t('first_enter') || item.name === this.$t('first_leave') ? item : null)
    } else {
      return [
        {
          name: this.$t('invite_link_enter'),
          data: [ this.link.enter ],
          type: 'bar'
        },
        {
          name: this.$t('invite_link_kick'),
          data: [ this.link.kick ],
          type: 'bar'
        },
        {
          name: this.$t('invite_link_leave'),
          data: [ this.link.leave ],
          type: 'bar'
        }
      ]
    }
  }

  get barOptions(): ApexOptions {
    return {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          format: 'YYYY-MM-DD HH:mm:ss',
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      }
    }
  }

  get lineOptions(): ApexOptions {
    return {
      chart: {
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          format: 'YYYY-MM-DD HH:mm:ss',
          show: false
        },
      },
    }
  }

  routeToStatistics(link: string) {
    this.$router.push({
      name: 'invite_links_statistics',
      params: {
        linkCode: link
      }
    })
  }

  clearSubscriber(link: InviteLink): string | undefined {
    if (link.price && link.leave !== undefined && link.enter !== undefined && link.kick !== undefined) {
      const clearPrice = Number(link.price / Number(link.enter - link.kick - link.leave))

      if (clearPrice !== Infinity) {
        return clearPrice.toFixed(2)
      }

      return '-'
    }
  }

  dirtySubscriber(link: InviteLink): string | undefined {
    if (link.price && link.enter !== undefined) {
      const clearPrice = Number(link.price / Number(link.enter))

      if (clearPrice !== Infinity) {
        return clearPrice.toFixed(2)
      }

      return '-'
    }
  }
}
