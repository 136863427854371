












































































































import LinkCard from '@/components/InviteLinks/LinkCard.vue'
import PageTitle from '@/components/PageTitle.vue'
import { InviteLink } from '@/includes/types/InviteLink.types'
import inviteLinksStatistics from '@/mixins/statistics/InviteLinks/inviteLinksStatistics'
import { InviteLinksService } from '@/includes/services/InviteLinksService'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import FieldTitle from 'piramis-base-components/src/components/Pi/components/FieldTitle.vue'

import { Mixins } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import { ListInviteLinkModel } from '@/components/InviteLinks/types'

@Component({
  'components': {
    LinkCard,
    PageTitle,
    List,
    FieldTitle,
  },
  'mixins': [ inviteLinksStatistics ],
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class LinkList extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  getBoardById!:GetBoardByIdType

  ListDirection: any = ListDirection

  links: Array<ListInviteLinkModel> = []

  linkStat: Object = {}

  rawLinks = []

  isLoading = false

  gotoLinkPage(actionType: 'new' | 'edit' = 'new', queryParams?: Record<string, string>): void {
    this.$router.push({
      name: 'invite_link',
      params: {
        actionType
      },
      query: queryParams ?? undefined
    })
  }

  linkSearch(item: InviteLink & { disable_auto_attack: boolean, expire: string }, index:number, text:string) {
    return item.tags && item.tags.some(t => t.includes(text)) || item.title.includes(text)
  }

  onEditLink(linkKey: string): void {
    this.gotoLinkPage('edit', { linkKey })
  }

  onDeleteLink(linkKey: string): void {
    this.$confirm({
      title: this.$t('link_list_popup_link_remove_text').toString(),
      okText: this.$t('accept').toString(),
      cancelText: this.$t('reject').toString(),
      onOk: () => {
        this.deleteInviteLink(linkKey)
      },
      centered: true,
    })
  }

  deleteInviteLink(key: string): void {
    this.$store.dispatch('deleteInviteLink', key)
  }

  getInviteLinksStat(): Promise<any> {
    return new Promise((resolve, reject) => {
      InviteLinksService.getInviteLinksStat('tg', {
        board_key: this.getBoardById(this.$route.params.id)!.board,
        target_id: Number.parseInt(this.$route.params.channelId),
        interval: {
          interval_type: 'Short',
        }
      })
        .then((data) => resolve(data.links))
        .catch(err => {
          errorNotification(err)
          reject(false)
        })
    })
  }

  hasLinkUniqueUsers(link:any):boolean {
    return link.items.some(i => i.first_enter > 0 || i.first_leave > 0)
  }

  get activeLinks() {
    const newLinks: any[] = []
    let linksCopy = cloneDeep(this.links)

    linksCopy.map((link: any) => {

      let enter_unique_first_users_sum = 0
      let leave_unique_users_sum = 0

      const linkWithStat: any = this.rawLinks.find((rawLink: any) => rawLink.data && rawLink.data.link === link.link && rawLink.items.length)

      if (linkWithStat && this.hasLinkUniqueUsers(linkWithStat)) {
        linkWithStat.items.forEach((item) => {
          enter_unique_first_users_sum += item.first_enter
          leave_unique_users_sum += item.first_leave
        })

        link.enter_unique_first_users = enter_unique_first_users_sum
        link.leave_unique_users = leave_unique_users_sum

        newLinks.push(link)
      }
    })

    return newLinks.sort((a: any, b: any) => {
      return b.enter_unique_first_users_sum - a.enter_unique_first_users_sum ||
        b.leave_unique_users_sum - a.leave_unique_users_sum
    })
  }

  get nonActiveLinks() {
    const newLinks: any[] = []

    this.links.forEach((link: any) => {
      const linkWithStat: any = this.rawLinks.find((rawLink: any) => rawLink.data && rawLink.data.link === link.link && rawLink.items)
      if (linkWithStat && !this.hasLinkUniqueUsers(linkWithStat)) {
        newLinks.push(link)
      }
    })
    return newLinks.sort((a: any, b: any) => b.enter - a.enter || b.kick - a.kick)
  }

  exportToCsv(): void {
    const options = {
      'fieldSeparator': ';',
      'quoteStrings': '"',
      'decimalSeparator': '.',
      'showLabels': true,
      'showTitle': true,
      'filename': this.$t('links_csv_table_title').toString(),
      'title': this.$t('links_csv_table_title').toString(),
      'useTextFile': false,
      'useBom': true,
      'useKeysAsHeaders': true,
      'headers': undefined,
    }

    const data = this.links.map((link) => {
      return {
        [this.$t('links_csv_title').toString()]: link.title,
        [this.$t('links_csv_approve').toString()]: this.$t(`link_approve_type_${ link.config.approve.toLocaleLowerCase() }`).toString(),
        [this.$t('links_csv_disable_auto_attack').toString()]: this.$t(`links_csv_disable_auto_attack_${ link.config.disable_auto_attack }`).toString(),
        [this.$t('links_csv_enter').toString()]: link.enter,
        [this.$t('links_csv_leave').toString()]: link. leave,
        [this.$t('links_csv_tags').toString()]: link.tags ? link.tags.join(', ') : '-',
        [this.$t('field_invite_link_model_price_title').toString()]: link.config.price,
        [this.$t('links_csv_date').toString()]: link.config.expire
      }
    })

    new ExportToCsv(options).generateCsv(data)
  }

  async mounted(): Promise<void> {
    this.isLoading = true
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')

    if (!this.$store.state.inviteLinksState.links) {
      await this.$store.dispatch('getInviteLinks')
    }
    this.links = this.$store.getters.inviteLinks

    const stat = await this.getInviteLinksStat()
    this.linkStat = inviteLinksStatistics.methods.prepareStatData(this, stat, true)

    this.rawLinks = stat

    this.isLoading = false
  }
}

